<template>
  <v-dialog v-model="dialog" width="900" retain-focus @click:outside="cancel" @keydown.esc="cancel">
    <v-card class="pa-4">
      <v-card-text class="mx-auto">
        {{ message }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    },
    message: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    cancel() {
      this.$emit('close')
    }
  }
}
</script>
